import { useAuth0 } from '@auth0/auth0-react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'

export function CrAlertNewAccount ({
  handleCopyApiKey,
  handleValidateUserPhone
}: {
  handleCopyApiKey: (apiKey: string) => void
  handleValidateUserPhone: (assetId: string) => void
}) {
  const [apiKey, setApiKey] = useState('')
  const { user } = useAuth0()

  useEffect((): void => {
    setApiKey(user?.cr_api_key)
  }, [])

  return (
    <Alert
      variant="outlined"
      severity="info"
      sx={{ mt: 3 }}
    >
        <Typography sx={{ mb: 2 }}>
          Hola {user?.nickname || ''}!<br />
          Parece que aun no tienes Cuentas activas. Descarga nuestro EA, instalalo en tu cuenta y empieza a gestionarla con Control Risk.
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                textAlign: 'center',
                margin: 2,
                '& img': {
                  width: {
                    xs: '40%',
                    sm: '40%',
                  },
                }
              }}
            >
              <img
                src="mt4-logo.png"
                alt="mt4"
                width="60%"
                onClick={() => handleValidateUserPhone('mt4')}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                textAlign: 'center',
                margin: 2,
                '& img': {
                  width: {
                    xs: '40%',
                    sm: '40%',
                  },
                }
              }}
            >
              <img
                src="mt5-logo.png"
                alt="mt5"
                width="60%"
                onClick={() => handleValidateUserPhone('mt5')}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Grid>
        </Grid>
        <Typography sx={{ my: 2 }}>
          Una vez instalado tu Expert Advisor en el Meta Trader asociado a tu cuenta, deberás añadirle el parametro Api Key.
          Lo puedes encontrar aquí a continuación o dentro de tu sección de <u>Perfil</u>.
          {/* Tienes dudas? Consulta nuestra Guía. */}
        </Typography>
        <Box sx={{ my: 2 }}>
          <TextField
            value={apiKey}
            label="Api Key"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleCopyApiKey(apiKey)}>
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "gray",
                },
                "&:hover fieldset": {
                  borderColor: "gray",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "gray",
                },
              },
            }}
          />
        </Box>
        <Typography sx={{ mt: 2 }}>
          Una vez aplicado tu Expert Advisor en el Meta Trader asociado a tu cuenta, los datos empezaran a aparecer automaticamente aquí.
        </Typography>
    </Alert>
  );
}
