import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { numberFormat } from '../../utils'
import { IAccount } from '../../interfaces'

export function CrTableHistories ({
  accounts,
  loadingDashboard,
}: any) {
  return (
    <>
      <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>
        Historico
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: 'grey.800' }}>
            <TableRow>
              <TableCell>Cuenta</TableCell>
              <TableCell align="right">Hoy</TableCell>
              <TableCell align="right">Ayer</TableCell>
              <TableCell align="right">Sem. Actual</TableCell>
              <TableCell align="right">Sem. Pasada</TableCell>
              <TableCell align="right">Mes Pasado</TableCell>
              <TableCell align="right">Trim. Pasado</TableCell>
              <TableCell align="right">Año Actual</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingDashboard ? (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            ) : (   
              !accounts ? (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={9} align="center" sx={{ color: 'error.main' }}>
                    Lo sentimos, parece que hay un error
                  </TableCell>
                </TableRow>
              ) : !accounts.length ? (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={9} align="center">
                    Parece que aun no tienes ninguna cuenta activa
                  </TableCell>
                </TableRow>
              ) : (
                accounts.map((account: IAccount) => (
                  <TableRow key={account.number} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      {account.name}&nbsp;[&nbsp;{account.number}&nbsp;]
                    </TableCell>
                    <TableCell align="right" sx={(account.history.today < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.history.today * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right" sx={(account.history.yesterday < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.history.yesterday * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right" sx={(account.history.currentWeek < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.history.currentWeek * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right" sx={(account.history.lastWeek < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.history.lastWeek * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right" sx={(account.history.lastMonth < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.history.lastMonth * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right" sx={(account.history.lastQuarter < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                    {numberFormat(account.history.lastQuarter * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right" sx={(account.history.currentYear < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.history.currentYear * 100)}&nbsp;%
                    </TableCell>
                    <TableCell align="right" sx={(account.history.allTime < 0) ? { color: 'error.main' } : { color: 'success.main' }}>
                      {numberFormat(account.history.allTime * 100)}&nbsp;%
                    </TableCell>
                  </TableRow>
                ))
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
