import axios, { AxiosResponse } from "axios"
import { IAccount, IAlert, IEnvParams, IUser } from "../interfaces"

export function addAccountNameToAlert (
  alerts: IAlert[] | undefined,
  accounts: IAccount[] | undefined
) {
  if (alerts && alerts.length &&
      accounts && accounts.length) {
    alerts.forEach((alert) => {
      alert.accountName = accounts.find((account) =>
        account.number === alert.accountNumber
      )?.name || ''
    })
  }
}

export function capitalizeFirstLetter (string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function datetimeFormat (isoDate: Date, withTime = true): string {
  const date = new Date(isoDate)

  let datetime
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  datetime = `${day}/${month}/${year}`

  if (withTime) {
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    datetime += ` ${hours}:${minutes}`
  }

  return datetime
}

export const envParams: IEnvParams = {
  HOSTNAME_SERVER: process.env.REACT_APP_HOSTNAME_SERVER || '',
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
}

export async function fetchAccounts (
  controlRiskServerUrl: string,
  token: string,
  userId: string
): Promise<IAccount[] | undefined> {
  try {
    const url = `${controlRiskServerUrl}/user/${userId}/accounts`
    const options = getOptions(token)
    const response = await axios.get(
      url,
      options
    )
    return response.data || []
  } catch (error) {
    console.log(new Error('Error fetching Accounts'))
    console.error(error)
  }
}

export async function fetchAlerts (
  controlRiskServerUrl: string,
  token: string,
  userId: string
): Promise<IAlert[] | undefined> {
  try {
    const response = await axios.get(
      `${controlRiskServerUrl}/user/${userId}/alerts`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: 5000
      })
    return response.data || []
  } catch (error) {
    console.log(new Error('Error fetching Accounts'))
    console.error(error)
  }
}

export async function fetchUser (
  controlRiskServerUrl: string,
  token: string,
  userId: string
): Promise<IUser | undefined> {
  try {
    const response = await axios.get(
      `${controlRiskServerUrl}/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: 5000
      })
    return response.data || {}
  } catch (error) {
    console.log(new Error('Error fetching User'))
    console.error(error)
  }
}

export async function fetchUserDownload (
  controlRiskServerUrl: string,
  token: string,
  userId: string,
  assetId: string
): Promise<AxiosResponse> {
  const response = await axios.get(
    `${controlRiskServerUrl}/user/${userId}/download/${assetId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 5000,
      responseType: 'blob'
    })
  return response
}

export function getEndOfLastMonth (): Date {
  const startOfLastMonth = getStartOfLastMonth()
  return new Date(startOfLastMonth.getFullYear(), startOfLastMonth.getMonth() + 1, 0, 23, 59, 59, 999)
}

export function getEndOfLastQuarter (): Date {
  const now = new Date()
  const currentQuarter = Math.floor(now.getMonth() / 3)
  let endOfPreviousQuarter
  if (currentQuarter === 0) {
    endOfPreviousQuarter = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59, 999)
  } else {
    endOfPreviousQuarter = new Date(now.getFullYear(), currentQuarter * 3, 0, 23, 59, 59, 999)
  }
  return endOfPreviousQuarter
}

export function getEndOfLastWeek (): Date {
  const startOfLastWeek = getStartOfLastWeek()
  const endOfLastWeek = new Date(startOfLastWeek);
  endOfLastWeek.setDate(endOfLastWeek.getDate() + 6);
  endOfLastWeek.setHours(23, 59, 59, 999);
  return endOfLastWeek
}

export function getEndOfYesterday (): Date {
  const now = new Date()
  const startOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
  const endOfYesterday = new Date(startOfYesterday)
  endOfYesterday.setHours(23, 59, 59, 999)
  return endOfYesterday
}

export function getOptions (token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 5000
  }
}

export function getStartOfCurrentWeek(): Date {
  const now = new Date()
  const dayOfWeek = now.getDay()
  const startOfCurrentWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
  );
  return startOfCurrentWeek
}

export function getStartOfCurrentYear (): Date {
  const now = new Date()
  const startOfCurrentYear = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0);
  return startOfCurrentYear
}

export function getStartOfLastMonth (): Date {
  const now = new Date()
  const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  const startOfLastMonth = new Date(startOfCurrentMonth)
  startOfLastMonth.setMonth(startOfLastMonth.getMonth() - 1)
  return startOfLastMonth
}

export function getStartOfLastQuarter (): Date {
  const now = new Date()
  const currentQuarter = Math.floor(now.getMonth() / 3)
  let startOfPreviousQuarter
  if (currentQuarter === 0) {
    startOfPreviousQuarter = new Date(now.getFullYear() - 1, 9, 1)
  } else {
    startOfPreviousQuarter = new Date(now.getFullYear(), (currentQuarter - 1) * 3, 1)
  }
  return startOfPreviousQuarter
}

export function getStartOfLastWeek (): Date {
  const now = new Date()
  const dayOfWeek = now.getDay()
  const startOfCurrentWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1))
  const startOfLastWeek = new Date(startOfCurrentWeek)
  startOfLastWeek.setDate(startOfLastWeek.getDate() - 7)
  return startOfLastWeek
}

export function getStartOfToday (): Date {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

export function getStartOfYesterday (): Date {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
}

export function numberFormat (number: number, digits: number = 2): string {
  return (number || 0).toFixed(digits)
}

