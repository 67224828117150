import { useAuth0 } from '@auth0/auth0-react'
import {
  AlertColor,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  ThemeProvider
} from '@mui/material'
import { AxiosError, AxiosResponse } from 'axios'
import { useState } from 'react'
import { CrAppBar } from './components/common/CrAppBar'
import { Dashboard } from './components/dashboard/Dashboard'
import { Landing } from './components/landing/Landing'
import { Loading } from './components/loading/Loading'
import { IUser } from './interfaces'
import { envParams, fetchUser, fetchUserDownload } from './utils'

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
})

export function App() {
  const [typeSnackbar, setTypeSnackbar] = useState<AlertColor>('info')
  const [messageSnackbar, setMessageSnackbar] = useState<string>('')
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [openDialogPhone, setOpenDialogPhone] = useState<boolean>(false)
  const { isAuthenticated, isLoading } = useAuth0()
  const { user, getAccessTokenSilently } = useAuth0()

  const handleOpenSnackbar = (type: AlertColor, message: string) => {
    setTypeSnackbar(type)
    setMessageSnackbar(message)
    setOpenSnackbar(true)
  }
  const handleCopyApiKey = (apiKey: string) => {
    navigator.clipboard.writeText(apiKey).then(() => {
      handleOpenSnackbar('success', 'La Api Key ha sido copiada con exito!')
    })
  }
  const handleDownloadAsset = async (assetId: string): Promise<void> => {
    try {
      const controlRiskServerUrl = envParams.HOSTNAME_SERVER
      const token = await getAccessTokenSilently()
      const response: AxiosResponse = await fetchUserDownload(
        controlRiskServerUrl,
        token,
        user?.cr_id,
        assetId
      )

      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} ${response.statusText}`)
      }

      const blob = new Blob([response.data])
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `control-risk.${(assetId === 'mt4' ? 'ex4' : 'ex5')}`
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 403) {
          handleOpenSnackbar('info', 'Tienes que especificar antes tu numero de telefono.')
        } else {
          handleOpenSnackbar('error', 'No ha sido posible descargar el fichero.')
        }
      } else {
        handleOpenSnackbar('error', 'No ha sido posible descargar el fichero.')
      }
    }
  }
  const handleValidateUserPhone = async (assetId: string): Promise<void> => {
    try {
      const controlRiskServerUrl = envParams.HOSTNAME_SERVER
      const token = await getAccessTokenSilently()
      const crUser: IUser | undefined = await fetchUser(
        controlRiskServerUrl,
        token,
        user?.cr_id
      )

      if (crUser?.phone) {
        handleDownloadAsset(assetId)
      } else {
        setOpenDialogPhone(true)  
      }
    } catch (error) {
      handleOpenSnackbar('error', 'Lo sentimos, parere que hay un error en este momento. Vuelve a intentarlo!')
    }
  }

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Loading />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {
        isAuthenticated &&
        <CrAppBar
          handleCopyApiKey = { handleCopyApiKey }
          handleValidateUserPhone = { handleValidateUserPhone }
        />
      }
      <Container>
        {
          isAuthenticated ?
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Dashboard
                  typeSnackbar = { typeSnackbar }
                  messageSnackbar = { messageSnackbar }
                  openSnackbar = { openSnackbar }
                  setOpenSnackbar = { setOpenSnackbar }
                  openDialogPhone = { openDialogPhone }
                  setOpenDialogPhone = { setOpenDialogPhone }
                  handleCopyApiKey = { handleCopyApiKey }
                  handleOpenSnackbar = { handleOpenSnackbar }
                  handleValidateUserPhone = { handleValidateUserPhone }
                />
              </Grid>
            </Grid> 
          </> :
          <Landing />
        }        
      </Container>
    </ThemeProvider>
  );
}