import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link
} from '@mui/material'
import { Dispatch } from 'react'

export function CrDialogConnectTelegram ({
  openDialogConnectTelegram,
  setOpenDialogConnectTelegram,
}: {
  openDialogConnectTelegram: boolean
  setOpenDialogConnectTelegram: Dispatch<React.SetStateAction<boolean>>
}) {
  const handleCloseDialogConnectTelegram = (): void => {
    setOpenDialogConnectTelegram(false)
  }

  return (
    <Dialog
      open = { openDialogConnectTelegram }
      onClose = { handleCloseDialogConnectTelegram }
    >
      <DialogTitle>
        Conectate a Telegram
      </DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Control Risk <b>envia las Alertas a traves de la aplicación de Telegram</b>.
            Para poder recibir las Alertas tienes que seguir estos simples pasos:
            <br /><br />
            1. Descarga la aplicación de Telegram en tu movil a traves del <Link
              href="https://play.google.com/store/apps/details?id=org.telegram.messenger"
              target="_blank"
              rel="noreferrer"
              color="inherit"
            >
              Google Play
            </Link> para Android o <Link
              href="https://apps.apple.com/es/app/telegram-messenger/id686449807"
              target="_blank"
              rel="noreferrer"
              color="inherit"
            >
              App Store
            </Link> para iOS
            <br /><br />
            2. Una vez que hayas descargado la aplicación y hayas creado un usuario, accede a nuestro bot <Link
              href="https://t.me/ControlRiskTradingBot"
              target="_blank"
              rel="noreferrer"
              color="inherit"
            >
              @ControlRiskTradingBot
            </Link> y dale al boton de "Empezar"
            <br /><br />
            *** img ***
            <br /><br />
            3. Como indicado en la descripción del bot, escribe el correo electronico con el que accede a Control Risk,
            para que el sistema te pueda reconocer y validar. Dentro de unos minutos, si todo ha funcionado correctamente,
            el bot te avisará con un mensaje
          </DialogContentText>
        </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCloseDialogConnectTelegram} color="inherit">
          Entendido
        </Button>
      </DialogActions>
    </Dialog>
  )
}